import React from 'react'
import ProfileApis from '../../../queries/profile'
import moment from 'moment'
import noDataImg from '../../../assets/images/No data-cuate.png'

function PurchasedCourses({ api, profileData }) {
    const { data: purchasedCourse } = ProfileApis.PurchasedCourseDetails()
    console.log("==purchasedCourse===", purchasedCourse)

    return (
        <div className="purchased_qst_sec">
            <div className="row justify-content-between">
                <div className="col-md-6">
                    <h4 className="mt-3">Purchased Courses</h4>
                    {
                        purchasedCourse?.data &&
                            purchasedCourse.data?.length > 0 ?
                            purchasedCourse.data.map((items, index) => {
                                return (

                                    <div className="row align-items-center mt-3">
                                        <div className="col-md-6">
                                            <h6>{items.Course}</h6>
                                            <p>Purchased on : {moment(items.purchasedDate).format('DD-MM-YYYY')}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${items.percentage * 100}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                            :
                            <>
                                <img src={noDataImg} className='w-75' />
                                <span>No data found</span>
                            </>
                    }
                </div>

                <div className="col-md-5">
                    <h4 className="mt-3">Activities</h4>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <table className="table">
                                <tbody>
                                    {
                                        profileData?.activities &&
                                        profileData?.activities.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{items.title}</td>
                                                    <td align="right">{moment(items.createdAt).format('DD/MM/YYYY')}</td>

                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div >

    )
}

export default PurchasedCourses