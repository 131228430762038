import React from 'react'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseVideoSection from './CourseVideoSection'
import CourseStatusBox from './CourseStatusBox'
import CourseOverview from './CourseOverview'
import CourseInstructorDetails from './CourseInstructorDetails'

function SingleView({learningData,learningNotes,refetchLearningData,courseId}) {
    return (
        <>
            <InnerPageHeader
                title='My Learnings'
                subtitile='My Account'
                titlePath='my-learnings'
                subTitlePath='my-account'
            />
            <section class="mylearning_contant_section">
                <div class="container">
                    <CourseVideoSection learningData={learningData} refetchLearningData={refetchLearningData}/>
                    <CourseStatusBox learningData={learningData} courseId={courseId}/>
                </div>
            </section>
            <section class="course_content_sec">
                <div class="container">
                    <div class="row">
                        <CourseOverview learningData={learningData} learningNotes={learningNotes}/>
                        <CourseInstructorDetails learningData={learningData}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleView