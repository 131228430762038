
import * as yup from "yup"

const ProfileDetailsSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().optional(),
    mobile: yup.string().max(10, "can't exceed mobile number length 10").required().matches(/^\d+$/,"Invalid phone number"),
    countryId:yup.string().optional().nullable(),
    stateId:yup.string().optional().nullable()
  })
  .required()
export default ProfileDetailsSchema