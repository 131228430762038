import { useQuery, useMutation } from '@tanstack/react-query'
import { addRating, completeTask, createCourseHistory, currentVideo, dltComment, getAnswers, getLearningNotes, getLearningVideo, getQandA, makeLikes, navigateVideo, nextVideo, postComments, videoProgress } from '../services/learning/learningService'

const LearningApis = {
    GetLearningVideo: (courseId) => {
        const response = useQuery({
            queryKey: ["get-learning-video", courseId],
            queryFn: () => getLearningVideo(courseId)
        })
        return response
    },
    GetLearningNotes: (courseId) => {
        const response = useQuery({
            queryKey: ["get-learning-notes", courseId],
            queryFn: () => getLearningNotes(courseId)
        })
        return response
    },
    GetQandA: (videoId) => {
        const response = useQuery({
            queryKey: ["get-q-and-a", videoId],
            queryFn: () => getQandA(videoId),
            enabled: videoId ? true : false
        })
        return response
    },
    GetQuestionAnswers: (qstnId, taskId) => {
        const response = useQuery({
            queryKey: ["get-answers", qstnId, taskId],
            queryFn: () => getAnswers(qstnId, taskId),
            enabled: qstnId ? true : false
        })
        return response
    },
    Postcomments: () => {
        const response = useMutation({
            mutationFn: (data) => postComments(data)
        })
        return response
    },
    MakeLikes: () => {
        const response = useMutation({
            mutationFn: (data) => makeLikes(data)
        })
        return response
    },
    DltComment: () => {
        const response = useMutation({
            mutationFn: (data) => dltComment(data)
        })
        return response
    },
    VideoProgress: () => {
        const response = useMutation({
            mutationFn: (data) => videoProgress(data)
        })
        return response
    },
    CurrentVideo: () => {
        const response = useMutation({
            mutationFn: (data) => currentVideo(data)
        })
        return response
    },
    CreateCourseHistory: () => {
        const response = useMutation({
            mutationFn: (data) => createCourseHistory(data)
        })
        return response
    },
    CompleteTask:()=>{
        const response = useMutation({
            mutationFn: (data) => completeTask(data)
        })
        return response
    },
    Addrating: () => {
        const response = useMutation({
            mutationFn: (data) => addRating(data)
        })
        return response
    }
}
export default LearningApis