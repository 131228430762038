import { useQuery, useMutation } from '@tanstack/react-query'
import { addToCart, dltCartItems, getCartItems, getCategories, getCourses, purchaseCourse, viewCourseDetails } from '../services/courses/courseService';

const CourseApis = {
    GetCourses: (categoryId, limit) => {
        const response = useQuery({
            queryKey: ["get-courses", categoryId, limit],
            queryFn: () => getCourses(categoryId,limit)
        })
        return response
    },
    ViewCourseDetails: (courseId) => {
        const response = useQuery({
            queryKey: ["view-course-details", courseId],
            queryFn: () => viewCourseDetails(courseId)
        })
        return response
    },
    AddtoCart: () => {
        const response = useMutation({
            mutationFn: (data) => addToCart(data)
        })
        return response
    },
    GetCartItems: () => {
        const response = useQuery({
            queryKey: ["get-cart-items"],
            queryFn: () => getCartItems()
        })
        return response
    },
    DltCartItems: () => {
        const response = useMutation({
            mutationFn: (courseId) => dltCartItems(courseId)
        })
        return response
    },
    PurchaseCourse: () => {
        const response = useMutation({
            mutationFn: (data) => purchaseCourse(data),

        })
        return response
    },
    GetCategories: () => {
        const response = useQuery({
            queryKey: ["get-categories"],
            queryFn: () => getCategories()
        })
        return response
    }

}
export default CourseApis;