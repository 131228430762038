import React from 'react'
import { Link,useNavigate } from 'react-router-dom'

function Categories({ categoriesList }) {
    const navigate=useNavigate()
    const navigateHandler = (catId) => {
        navigate('/courses', { state: { category: catId } })
    }
    return (

        <section className="home_category_section">
            <div className="container">
                <div className="main_heading_txt">
                    <div className="row align-items-center">
                        <div className="col-md-6" data-aos="fade-up">
                            <h2>
                                Explore Our<br />
                                <strong>Popular Categories</strong>
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Discover a world of knowledge and opportunities with our online education platform pursue a new career.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    {
                        categoriesList?.data &&
                        categoriesList?.data.map((item, index) => {
                            return (
                                <div className="col-md-3" data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}>
                                    <a href="#">
                                        <div className="category_box" onClick={() => navigateHandler(item.id)}>
                                            <div className="category_box_ico"><i className={item.icon}></i></div>
                                            <div className="category_box_cnt">
                                                <strong>{item.name}</strong>
                                                <span>{item.description}</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <div className="viewAllBtn" data-aos="fade-down">
                <Link to='/categories'>View All Categories <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
        </section>
    )
}

export default Categories