import React, { useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseApis from '../../../queries/course'
import { useNavigate } from 'react-router-dom'
import emptyCartImg from '../../../assets/images/emptycart.png'
import toast from 'react-hot-toast'
import { useSubmitHandler } from '../../../context/PaymentSubmitContext'
import { useQueryClient } from "@tanstack/react-query";


function Cart() {
  const { isAuthenticated } = useAuth()
  const { submitRef, setpaymentLoading } = useSubmitHandler()
  const { data: cartItems, refetch } = CourseApis.GetCartItems()
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const dltCartItems = CourseApis.DltCartItems()
  const purchase = CourseApis.PurchaseCourse()
  const handleDlt = async (id) => {
    const response = await dltCartItems.mutateAsync(id)
    if (response.status) {
      await refetch()
    }
  }


  const checkoutHandler = () => {
    if(cartItems?.data?.products.length==0) return;
    submitRef.current = async (paymentMethod, paymentResponse) => {

      try {
        if (paymentResponse) {
          setpaymentLoading(true)
          const response = await purchase.mutateAsync({ paymentMethod, paymentResponse });

          if (response.status) {
            setpaymentLoading(false)
            toast.success("Purchase successful");
            queryClient.invalidateQueries('get-logged-user-details')
            navigate('/my-account');

          } else {
            setpaymentLoading(false)
            toast.error("Purchase failed");
            navigate('/cart');
          }
        }
      } catch (error) {
        toast.error("Purchase failed");
      }
    };


    navigate('/payment');
  };

  return (
    <>
      <InnerPageHeader
        title="Cart"
        subtitile='Courses'
        titlePath='cart'
        subTitlePath='courses'
      />
      <section className="cartContantArea">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="cart_top_row">
                <span>Total {cartItems?.data?.details?.totalCount} Items</span>
                {
                  cartItems?.data?.products.length > 0 &&
                  <a href="#" className="clearCartBtn" onClick={() => handleDlt('')}>Clear All</a>
                }

              </div>
              <div className="cart_cnt">
                {
                  cartItems?.data?.products.length > 0 ?
                    cartItems?.data?.products?.map((item, index) => {
                      return (
                        <div className="cart_cnt_row">
                          <div className="cart_cnt_row_img">
                            <img src="assets/images/course1.jpg" alt="Course" />
                          </div>
                          <div className="cart_cnt_row_cnt">
                            <div className="cart_cnt_row_cnt_left">
                              <h4>{item.title}</h4>
                              <span>Tutor: {item.tutor}</span>
                              <span>{item.rating}</span>
                              <span>{item.hours.hours} hours | {item.count.task} Lectures | {item.difficulty}</span>
                            </div>
                            <div className="cart_cnt_row_cnt_right">
                              <span>
                                Total
                                <strong>${item.discount.discountPrice}</strong>
                              </span>
                              <a href="#" className="cartSingleDelete" onClick={() => handleDlt(item.id)}>
                                <i className="fa fa-trash"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    <div>
                      <center>
                        <img src={emptyCartImg} className='w-25' /><br />
                        <button className='continue-shopping' onClick={() => navigate('/courses')}>Continue shopping</button>
                      </center>


                    </div>

                }


              </div>
            </div>
            <div className="col-md-4">
              <div className="cart_right_checkout">
                <h4>Checkout</h4>
                <div className="cart_right_checkout_dtl">
                  <div className="cart_right_checkout_dtl_cnt">
                    {
                      cartItems?.data?.products?.map((item, index) => {
                        return (
                          <div className="cart_right_checkout_dtl_row">
                            <span>{item.title}</span>
                            <span>${item.discount.discountPrice}</span>
                          </div>
                        )
                      })
                    }

                    {/* <div className="cart_right_checkout_dtl_row">
                      <span>Course Name</span>
                      <span>$500</span>
                    </div> */}
                  </div>
                  <div className="cart_right_checkout_btm">
                    <div className="cart_right_checkout_dtl_row">
                      <span>Total</span>
                      <span>${cartItems?.data?.details?.totalPrice}</span>
                    </div>
                    <div className="cart_right_checkout_dtl_row">
                      <span>Tax</span>
                      <span>${cartItems?.data?.details?.tax}</span>
                    </div>
                    <div className="cart_right_checkout_dtl_row checkout_payble">
                      <span>Total</span>
                      <span>${cartItems?.data?.details?.totalPrice}</span>
                    </div>
                  </div>
                </div>
                <a href="#" className="checkoutBtnCart"
                  onClick={checkoutHandler}
                >Checkout</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Cart