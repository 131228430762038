import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { SetUserType, SetloginStatus } from '../redux/reducers/slices/loginSlice';
import AuthApis from '../queries/auth';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const userLogout = AuthApis.Logout()
    const [userType, setUserType] = useState(null);
    const dispatch = useDispatch()
    useEffect(() => {
        const loginStatus = localStorage.getItem('loginStatus') === 'true';
        const storedUserType = localStorage.getItem('userType');
        setIsAuthenticated(loginStatus);
        setUserType(storedUserType);
    }, []);

    const login = (userType) => {
        dispatch(SetloginStatus(true))
        dispatch(SetUserType('student'))
        localStorage.setItem('loginStatus', 'true');
        localStorage.setItem('userType', userType);
        setIsAuthenticated(true);
        setUserType(userType);
    };

    const logout = () => {
        // userLogout.mutate()
        localStorage.removeItem('loginStatus');
        localStorage.removeItem('userType');
        setIsAuthenticated(false);
        setUserType(null);

    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userType, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};