import React from 'react'

function Modules({ courseDetails }) {
    return (
        <>
            <div className="col">
                <h4>Course Content</h4>
                <p>{courseDetails?.data?.counts?.modules} Sections * {courseDetails?.data?.counts?.tasks} Lectures * {courseDetails?.data?.duration?.hour?.hours}h {courseDetails?.data?.duration?.hour?.minutes}m total length</p>

                <div className="accordion moduleAccordion" id="accordionExample">
                    {
                        courseDetails?.data?.course?.modules.map((item, index) => {
                            const totalMinutes = parseInt(item.duration, 10);
                            const hours = Math.floor(totalMinutes / 60);
                            const minutes = totalMinutes % 60;
                            return (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded={index === 0 ? "true" : "false"}
                                            aria-controls={`collapse${index}`}
                                        >
                                            <span>
                                                {item.name}
                                                <p>Module {index + 1} | {hours} Hour{hours !== 1 ? 's' : ''} {minutes > 0 ? ` ${minutes} Minute${minutes !== 1 ? 's' : ''}` : ''}</p>
                                            </span>
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {/* <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            )


                        })
                    }



                </div>
            </div>
        </>
    )
}

export default Modules