import React, { useEffect, useState } from 'react'
import Chart from 'chart.js/auto';
import ProfileApis from '../../../queries/profile';
import profilePic from '../../../assets/images/profilepic.png'
import VerifyMail from './VerifyMail';
import toast from 'react-hot-toast'

function UserDetails({ api, profileData, learningReportData }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        sendVerificationMail()
    };
    const updateProfilePic = ProfileApis.UpdateProfilePic()
    const dltProfilePic = ProfileApis.DltProfilePic()
    const verifyMail = ProfileApis.VerifyMail()
    const sendVerificationMail = async () => {
        const response = await verifyMail.mutateAsync()
        console.log("responsesendVerificationMail =============== ", response);
        if (response.status) {
            toast.success(response.message)
        }
    }
    console.log("===profileData====", profileData)
    useEffect(() => {
        const ctx = document.getElementById("mylearning").getContext('2d');
        const barChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                // datasets: [
                //     {
                //         label: 'This Month',
                //         data: [12, 19, 3, 17, 28, 24, 7],
                //         // data: [0,0,0,0,0,0,0],
                //         backgroundColor: "#32B1D9"
                //     },
                //     {
                //         label: 'Last Month',
                //         data: [30, 29, 5, 5, 20, 3, 10],
                //         // data: [0,0,0,0,0,0,0],
                //         backgroundColor: "#040136"
                //     }
                // ]
                datasets: learningReportData?.datasets
            },
            options: {
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }]
                }
            }
        });

        return () => {
            barChart.destroy();
        };
    }, [learningReportData]);

    const profilePicHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const response = await updateProfilePic.mutateAsync(formData)
        if (response.status) {
            await api.refetch()
        }

    }

    const handleDeleteProfilePic = async () => {
        await dltProfilePic.mutateAsync()
        await api.refetch()
    }
    return (
        <div className="my_account_top_section">
            <div className="row justify-content-between">
                <div className="col-md-5">
                    <div className="profile_section">
                        <button className="delete-profile-pic-btn" onClick={handleDeleteProfilePic}>
                            <i className="fa-solid fa-trash" style={{ color: 'white' }}></i>
                        </button>
                        <div className="profile_top_dtl">
                            <div className="profile_image">

                                <label htmlFor="file-input">
                                    <img src={profileData?.user_detail?.profilePhoto ?? profilePic} />
                                </label>
                                <input id="file-input" type="file" style={{ display: 'none' }} onChange={profilePicHandler} />
                            </div>
                            <div className="profile_usr_dtl_cnt">
                                <p>Hi,</p>
                                <h3>{profileData?.username}</h3>
                                <p>Your subscription ends on <span>25 February 2015</span></p>
                            </div>
                        </div>
                        <div className="profile_btn_row">
                            {/* {profileData?.user_detail.emailVerified == 0 ? <a href="#" className="btn btn-primary">Verify Account</a> : <div className="btn btn-primary">Verified <i className="fa-solid fa-circle-check" style={{ "color": "#63E6BE" }}></i></div>} */}
                            {profileData?.user_detail.emailVerified == 0 && <a href="#" className="btn btn-primary" onClick={handleShow}>Verify Account</a>}
                            {/* <a href="#" className="btn btn-secondary">Edit Profile</a> */}
                        </div>
                        {
                            profileData?.certificates?.length > 0 &&
                            <div className="profile_btn_row1">
                                <p>Download Certificates</p>
                                {
                                    profileData?.certificates.map((item, index) => {
                                        return (
                                            <a href="#" className="btn btn-light">Option {index + 1}</a>
                                        )
                                    })
                                }


                            </div>
                        }

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="profile_learinin_report">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-9">
                                <h5>Learning Report</h5>
                                <canvas id="mylearning"></canvas>
                            </div>
                            <div className="col-md-3">
                                <span>
                                    <p>This Month</p>
                                    <strong>{learningReportData?.currentMonthSpent.toFixed(3)}hr</strong>
                                </span>
                                <span>
                                    <p>Last Month</p>
                                    <strong>{learningReportData?.prevMonthSpent.toFixed(3)}hr</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifyMail profileApi={api} show={show} handleClose={handleClose} sendVerificationMail={sendVerificationMail} mail={profileData?.email} />
            </div>
        </div>


    )
}

export default UserDetails