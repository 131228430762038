import React from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import CourseApis from '../../queries/course'
import { useNavigate } from 'react-router-dom'
function Categories() {
    const { data: Categories } = CourseApis.GetCategories()
    
    const navigate = useNavigate()
    console.log("===Categories=======", Categories?.data)
    const navigateHandler = (catId) => {
        navigate('/courses', { state: { category: catId } })
    }
    return (
        <div>
            <InnerPageHeader
                title="Categories"
                subtitile="My Account"
                titlePath="categories"
                subTitlePath="my-account"
            />
            <section className="home_category_section">
                <div className="container">
                    <div className="main_heading_txt">
                        <div className="row align-items-center">
                            <div className="col-md-6" data-aos="fade-up">
                                <h2>
                                    Explore Our<br />
                                    <strong>Popular Categories</strong>
                                </h2>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    Discover a world of knowledge and opportunities with our online education platform pursue a new career.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            Categories?.data &&
                            Categories?.data.map((item, index) => {
                                return (
                                    <div className="col-md-3" data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}>
                                        <a href="#">
                                            <div className="category_box" onClick={()=>navigateHandler(item.id)}>
                                                <div className="category_box_ico"><i className={item.icon}></i></div>
                                                <div className="category_box_cnt">
                                                    <strong>{item.name}</strong>
                                                    <span>{item.description}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Categories