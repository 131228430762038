import React from 'react'

function Overview() {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h4>What you’ll learn</h4>
                    <p>
                        Dunt ut labore et dolore ma gna aliquaim ad minim vul koreo laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit animDunt ut labore et dolore ma gna aliquaim ad minim vul koreo laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                    </p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <h4>This course includes:</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                <li>Dunt ut labore et dolore ma gna.</li>
                                <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                <li>Dunt ut labore et dolore ma gna.</li>
                                <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview