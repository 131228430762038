
import * as yup from "yup"

const Registerschema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().optional(),
    email: yup.string().email('invalid email').required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,"Invalid mail format"),
    mobile: yup.string().max(10, "can't exceed mobile number length 10").required().matches(/^\d+$/,"Invalid phone number"),
    password: yup.string().required().min(8, "minimum length is 8")
  })
  .required()
export default Registerschema